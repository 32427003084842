/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/* globals window document */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Transportation from './transportation'
import Accommodation from './accomodation'
import isMobileDevice from './isMobileDevice'

import Form from './inputs'
import "./layout.css"

const Container = styled.div`
  display: flex; 
  justify-content: center;
  height: 100vh;
`

const Body = styled.div`
  width: 80vw;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-gap: 16px;
  position: relative;
  // border: 1px solid red;

  @media only screen and (max-width: 1024px) {
    width: 98vw;
    grid-template-columns: 1fr;
    padding: 0 1vw;
  }
`

const LongSide = styled.div`
  display: grid;
  align-tems: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  // delete for non-fixed height
  // overflow-y: scroll;
  // scroll-snap-type: y mandatory; // apply this to html element if removing fixed-height

  // ::-webkit-scrollbar { /* WebKit */
  //   width: 0;
  //   height: 0;
  // }
`

const LongSideGrid = styled.div`
  display: grid;
  width: 450px;
  grid-template-rows: 100vh 60vh auto 60vh 60vh;
  align-items: center;
  justify-content: center;
  scroll-snap-type: y mandatory; 

  @media only screen and (max-width: 1024px) {
    width: 98vw;
    grid-template-rows: repeat(6, 100vh);
  }
`

const Half = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SmallText = styled.p`
  font-weight: 200;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 4px;
  line-height: 25px;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 1024px) {
    font-weight: 300;
    padding: 0 12px;
  }
`

const DateText = styled.h2`
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 4px;
  color: #725b48;
  margin: 32px 0 0 0;
`

const SundayText = styled.p`
  font-weight: 200;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 4px;
  line-height: 25px;
  padding: 0;
  margin: 0 0 32px 0;

  @media only screen and (max-width: 1024px) {
    font-weight: 300;
    padding: 0 12px;
  }
`

const SeacliffHouse = styled.p`
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 4px;
  color: #725b48;
  padding: 0;
  margin: 4px 0;
`

const MoreInfo = styled.p`
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 4px;
  color: #9F836B;
  padding: 0;
  margin: 24px 0 0 0;
  cursor: pointer;
`

const InfoSection = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; 
  width: 100%;
  scroll-snap-align: start;
`

const FormSection = styled.div`
  max-height: ${({ maxHeight }) => maxHeight};
  transition: max-height ease 1s;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; 
  width: 100%;
  scroll-snap-align: start;
`

const Layout = ({ setPortraitLoaded, setWritingLoaded }) => {
  const matches = useMediaQuery('(max-width:1024px)');
  const [maxHeight, setMaxHeight] = useState('160px')

  useEffect(() => {
    if (isMobileDevice()) {
      window.addEventListener("resize", function(){
         if(document.activeElement.tagName=="INPUT"){
            window.setTimeout(function(){
               document.activeElement.scrollIntoView({ block: 'center' });
            },0);
         }
      })
    }
  });

  if (matches) {
    return (
      <Container>
        <Body>
            <LongSideGrid>
              <InfoSection>
                <img src="https://abaio.s3-ap-southeast-2.amazonaws.com/AA_Portrait.png" alt="" onLoad={() => setPortraitLoaded(true)} />
                <div className='hg-shimmy'>
                  <ExpandMoreIcon />
                </div>
              </InfoSection>
              <InfoSection>
                <div style={{ maxWidth: '600px' }}>
                  <img src="https://abaio.s3-ap-southeast-2.amazonaws.com/AA_Writing.png" alt="" onLoad={() => setWritingLoaded(true)} />
                </div>
              </InfoSection>
              <InfoSection>
                  <SmallText>REQUEST THE PLEASURE OF YOUR COMPANY AS THEY CELEBRATE THEIR MARRIAGE</SmallText>
                  <DateText>02.11.2021</DateText>
                  <SundayText>(YES, THAT'S A TUESDAY!)</SundayText>
                  <SmallText> THREE O'CLOCK IN THE AFTERNOON</SmallText>
                  <SeacliffHouse>SEACLIFF HOUSE</SeacliffHouse>
                  <SmallText>139 PRINCES HIGHWAY, GERRINGONG</SmallText>
                  {/* <MoreInfo onClick={() => document.getElementById('hg-transportation').scrollIntoView({ behavior: 'smooth', block: 'center' })}> */}
                    <div className='hg-shimmy-2'>
                      <ExpandMoreIcon />
                    </div>
                  {/* </MoreInfo> */}
              </InfoSection>
              <InfoSection>
                <Form />
              </InfoSection>
              <InfoSection>
                  <Accommodation />
              </InfoSection>
              <InfoSection>
                <Transportation />
              </InfoSection>
            </LongSideGrid>
        </Body>
      </Container>
    );
  }

  return (
    <Container>
      <Body>
        <Half>
          <LongSide>
            <LongSideGrid>
              <InfoSection id="hg-scroll">
                <img src="https://abaio.s3-ap-southeast-2.amazonaws.com/AA_Writing.png" alt="" onLoad={() => setWritingLoaded(true)} />
                {/* <div className='hg-shimmy'>
                  <ExpandMoreIcon />
                </div> */}
              </InfoSection>
              <InfoSection>
                  <SmallText>REQUEST THE PLEASURE OF YOUR COMPANY AS THEY CELEBRATE THEIR MARRIAGE</SmallText>
                  <DateText>02.11.2021</DateText>
                  <SundayText>(YES, THAT'S A TUESDAY!)</SundayText>
                  <SmallText> THREE O'CLOCK IN THE AFTERNOON</SmallText>
                  <SeacliffHouse>SEACLIFF HOUSE</SeacliffHouse>
                  <SmallText>139 PRINCES HIGHWAY, GERRINGONG</SmallText>
                  {/* <MoreInfo onClick={() => document.getElementById('hg-transportation').scrollIntoView({ behavior: 'smooth', block: 'center' })}> */}
                    {/* <div className='hg-shimmy2'>
                      <ExpandMoreIcon />
                    </div> */}
                  {/* </MoreInfo> */}
              </InfoSection>
              <FormSection maxHeight={maxHeight}>
                <Form maxHeight={maxHeight} setMaxHeight={setMaxHeight} />
              </FormSection>
              <InfoSection>
                  <Accommodation />
              </InfoSection>
              <InfoSection>
                <Transportation />
              </InfoSection>
            </LongSideGrid>
          </LongSide>
        </Half>
      </Body>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
