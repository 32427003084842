/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import 'whatwg-fetch'
import clsx from 'clsx';
import PropTypes from "prop-types"
import FormControl from '@material-ui/core/FormControl';
import UnstyledInput from '@material-ui/core/Input';
import UnstyledInputLabel from '@material-ui/core/InputLabel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
  useAsync,
} from 'react-async-hook';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import styled from "@emotion/styled"
import UnstyledRadio from '@material-ui/core/Radio';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import UnstyledAutocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CSSTransition, TransitionGroup } from 'react-transition-group' // ES6
import isMobileDevice from './isMobileDevice'

import "./layout.css"

const StyledForm = styled.form`
  width: 100%;
  height: 50vh;
  @media only screen and (max-width: 1280px) {
    padding: 0 1vw;
  }
`

const RSVPContainer = styled.div`
  display: grid; 
  grid-gap: 8px;
  grid-auto-flow: row;
  grid-template-columns: 4fr 1fr 4px 1fr;
  width: 100%;
  margin: 0 0 16px 0;
`

const AutocompleteContainer = styled.div`
  display: flex; 
  width: 100%;

  @media only screen and (max-width: 1024px) {
    padding: 0 16px;
  }

  // opacity: ${({ hasSelectedGuest, loadingParty }) => !hasSelectedGuest || loadingParty ? '1' : '0' };
  // height: ${({ hasSelectedGuest, loadingParty }) => !hasSelectedGuest || loadingParty ? 'auto' : '0' };
  // transition: opacity 1s linear;
`

const FormRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
`

const SmallText = styled.p`
  font-weight: 200;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 4px;
  line-height: 22px;
  padding: 0;
  margin: 0;
`

const BigText = styled.p`
  font-weight: 300;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 4px;
  line-height: 22px;
  padding: 0;
  margin: 0;
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const DietContainer = styled.div`
  margin: 16px 0;
  display: grid;
  grid-gap: 8px;
  flex-direction: column;
`

const OptionsText = styled.div`
  font-size: 13px;
  letter-spacing: 2px;
`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    background: '#C7AD98 !important',
    cursor: 'default',
    '&$disabled': {
      background: '#C7AD98 !important',
    }
  },
  fabProgress: {
    // color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: '#725b48',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
}));

const StyledButton = withStyles({
  root: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    background: '#725B48',
    borderRadius: 40,
    border: 0,
    color: 'white',
    height: 30,
    margin: '18px 0 0 0',
    padding: '0 16px',
    fontWeight: 400,
    letterSpacing: 2,
    boxShadow: '0 3px 4px 2px rgba(0, 0, 0, .2)',
    '&:hover': {
      background: '#9F836B',
    },
    //   textDecoration: 'none',
    //   backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
    //   // Reset on touch devices, it doesn't add specificity
    //   '@media (hover: none)': {
    //     backgroundColor: 'transparent',
    //   },
    //   '&$disabled': {
    //     backgroundColor: 'transparent',
    //   },
    // },
    // '&$disabled': {
    //   color: theme.palette.action.disabled,
    // },
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.8)',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const Radio = withStyles({
  root: {
    color: '#9F836B',
    '&$checked': {
      color: '#9F836B',
    },
  },
  checked: {},
})(UnstyledRadio);

const Input = withStyles({
  root: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // borderRadius: 3,
    // border: 0,
    // color: 'white',
    height: 42,
    // padding: '0 18px',
    // boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.1)',
    // textTransform: 'capitalize',
    // // '& .MuiInput-input': {
    // //   textTransform: 'capitalize',
    // // },
  },
  input: {
    fontWeight: 400,
    // color: '#725B48',
    fontSize: '13px',
    textOverflow: 'ellipsis',
    letterSpacing: 2,
  },
  label: {
    fontWeight: 200,
    color: '#725B48',
    letterSpacing: 0,
  },
  disabled: {
  },
  underline: {
    '&:after': {
      borderBottom: '2px solid #725B48',
    },
    '&:hover:not($disabled):before': {
      borderBottom: '2px solid #725B48',
    }
  },
  focused: {
    // color: "#9F836B",
  }
})(UnstyledInput);

const Autocomplete = withStyles({
  root: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // borderRadius: 3,
    border: 0,
    // color: 'white',
    // height: 42,
    color: 'rgba(0, 0, 0, 0.85)',
    width: '100%',
    fontWeight: 300,
    letterSpacing: 0,
    // padding: '0 18px',
    // boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.1)',
    // textTransform: 'capitalize',
    // // '& .MuiInput-input': {
    // //   textTransform: 'capitalize',
    // // },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#725B48',
    },
    '& .MuiFormLabel-root': {
      color: '#725B48',
      fontWeight: 300,
      letterSpacing: 2,
    },
    '& .MuiAutocomplete-listbox	': {
      fontSize: '13px',
      textTransform: 'uppercase',
    },
  },
  input: {
    fontWeight: 300,
    letterSpacing: 0,
  },
  label: {
    fontWeight: 200,
    letterSpacing: 0,
  },
  disabled: {
  },
})(UnstyledAutocomplete);

const InputLabel = withStyles({
  root: {
    fontWeight: 300,
    letterSpacing: 2,
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // borderRadius: 3,
    // border: 0,
    color: '#725B48',
    // height: 42,
    // padding: '0 18px',
    // boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.1)',
    // textTransform: 'capitalize',
    // // '& .MuiInput-input': {
    // //   textTransform: 'capitalize',
    // // },
  },
  input: {
    fontWeight: 300,
    letterSpacing: 0,
  },
  focused: {
    color: '#725B48 !important',
  },
})(UnstyledInputLabel);

// https://stackoverflow.com/a/28046731
// https://react-async-hook.netlify.com/
// https://github.com/slorber/react-async-hook/blob/master/example/index.tsx
const searchGuests = async (text) => {
  const result = await fetch(`https://9ohsj95glg.execute-api.ap-southeast-2.amazonaws.com/dev/users/${text}`);
  if (result.status !== 200) {
    throw new Error('bad status = ' + result.status);
  }
  const json = await result.json();
  return json;
};

const useSearchGuest = () => {
  // Handle the input text state
  const [inputText, setInputText] = useState('');

  // Debounce the original search async function
  const debouncedSearchGuest = useConstant(() =>
    AwesomeDebouncePromise(searchGuests, 1000)
  );

  const search = useAsync(
    async (text) => {
      if (text.length === 0) {
        return [];
      } else {
        return debouncedSearchGuest(text);
      }
    },
    // Ensure a new request is made everytime the text changes (even if it's debounced)
    [inputText]
  );

  // Return everything needed for the hook consumer
  return {
    inputText,
    setInputText,
    search,
  };
};

// https://gist.github.com/koistya/934a4e452b61017ad611#gistcomment-2683552
// FOR PARALAX
const Form = ({ children, setMaxHeight = () => '160px', maxHeight = '160px' }) => {
  const classes = useStyles();
  const [hasSelectedGuest, setHasSelectedGuest] = useState(false)
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [loadingParty, setLoadingParty] = useState(false)
  const [loadingRSVP, setLoadingRSVP] = useState(false)
  const [rsvpSuccess, setRSVPSuccess] = useState(false)
  const { inputText, setInputText, search } = useSearchGuest();
  const [party, setParty] = useState([])

  const fetchGuestLoading = autocompleteOpen && search.loading;
  const showDiet = party.length > 0 && party.every(x => x.rsvp !== 'null')
  const attendants = party.some(x => x.rsvp === "Yes") 

  const buttonClassname = clsx({
    [classes.buttonSuccess]: rsvpSuccess,
  });

  const handleBack = async () => {
    await setHasSelectedGuest(false); // janky.
    await setRSVPSuccess(false)
    await setMaxHeight('160px')
  }

  const handleChange = async fuck => {
    await setLoadingParty(true); // janky.
    await axios.get(`https://9ohsj95glg.execute-api.ap-southeast-2.amazonaws.com/dev/guests/${fuck.id}`)
      .then(async res => {
        await setParty(res.data);
        await setLoadingParty(false)
        await setHasSelectedGuest(true); // janky.
        await setMaxHeight('600px')
      })
      .catch(async err => {
        await setLoadingParty(false)
      })
  }

  const handleSubmit = async () => {
    await setLoadingRSVP(true); // janky.
    if (!rsvpSuccess) {
      axios.put('https://9ohsj95glg.execute-api.ap-southeast-2.amazonaws.com/dev/guests', {
        guests: party.map(x => ({ id: x.id, rsvp: x.rsvp, diet: x.diet }))
      })
        .then(async res => {
          await setLoadingRSVP(false)
          await setRSVPSuccess(true)
          await setMaxHeight('640px')
        })
        .catch(async err => {
          await setLoadingRSVP(false)
        })
    }
  }

  const handleRadioChange = id => event => {
    setParty(party.map(x => x.id === id ? ({ ...x, rsvp: event.target.value }): x))
  }

  const handleDietChange = event => {
    setParty(party.map(x => ({ ...x, diet: event.target.value })))
  }

  return (
    <StyledForm maxHeight={maxHeight} autoComplete="off">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CSSTransition
          classNames="hg-autocomplete-container"
          in={!hasSelectedGuest || loadingParty}
          mountOnEnter
          unmountOnExit
          onExited={() => {
            if (isMobileDevice()) {
              window.setTimeout(function() { document.getElementById('hg-rsvp-container').scrollIntoView({ block: 'center' })}, 1000)
            }
          }}
          timeout={{ enter: 1000, exit: 500 }}
        >
          <AutocompleteContainer key="autocomplete-container">
            <FormControl style={{ width: '100%' }}>
              <Autocomplete
                inputValue={inputText}
                id="asynchronous-search"
                open={autocompleteOpen}
                onOpen={() => {
                  setAutocompleteOpen(true);
                }}
                onClose={() => {
                  setAutocompleteOpen(false);
                }}
                ListboxProps={{ style: { fontSize: '13px', textTransform: 'uppercase', letterSpacing: '2px' }}}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={option => option.name}
                options={search.result || []}
                noOptionsText={<OptionsText>NO OPTIONS</OptionsText>}
                loadingText={<OptionsText>LOADING...</OptionsText>}
                loading={fetchGuestLoading || loadingParty}
                onInputChange={(e, _, reason) => reason === 'input' ? setInputText(e.target.value) : console.log('rekt')}
                onChange={(event, value) => handleChange(value)}
                tabIndex="-1"
                renderInput={params => (
                  <TextField
                    {...params}
                    tabIndex="-1"
                    label="ENTER YOUR NAME TO RSVP"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {fetchGuestLoading || loadingParty ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </AutocompleteContainer>
        </CSSTransition>
        <CSSTransition
          classNames="hg-rsvp-container"
          in={hasSelectedGuest && !loadingParty}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 1000, exit: 500 }}
        >
          <div id="hg-rsvp-container" style={{ display: 'grid', padding: '6px' }}>
            <RSVPContainer key="rsvp-container">
              <SmallText style={{ display: 'flex', cursor: 'pointer', position: 'relative', left: '-6px' }} onClick={handleBack}>
                <>
                <ChevronLeftIcon style={{ color: '#725b48', fontSize: '1.4rem' }} />
                {'BACK'}
                </>
                </SmallText>
              <SmallText>JOYFULLY <br />ACCEPT</SmallText>
                <div />
              <SmallText>REGRETFULLY<br /> DECLINE</SmallText>
              {party.map((x, i) => (
                <>
                <FormControl>
                  <InputLabel htmlFor={`${x.name}-name`}>GUEST #{i + 1}</InputLabel>
                  <Input disableUnderline id={`${x.name}-name`} defaultValue={x.name.toUpperCase()} disabled />
                </FormControl>
                <RadioContainer>
                  <div>
                    <Radio type="radio" checked={x.rsvp === "Yes"} size="small" onChange={handleRadioChange(x.id)} value="Yes"/>
                  </div>
                </RadioContainer>
                <div />
                <RadioContainer>
                  <div>
                    <Radio type="radio" checked={x.rsvp === "No"} size="small" onChange={handleRadioChange(x.id)} value="No"/>
                  </div>
                </RadioContainer>
                </>
              ))}
            </RSVPContainer>
            <TransitionGroup component={DietContainer} className="diet-list">
              {(showDiet && attendants) && (
                <CSSTransition
                  key="hg-diet"
                  timeout={500}
                  classNames="item"
                >
                  <FormControl>
                    <InputLabel htmlFor='diet'>{`DIETARY REQUIREMENTS`}</InputLabel>
                    <Input id='diet' defaultValue={party[0].diet === 'null' ? null : party[0].diet.toUpperCase()} onChange={handleDietChange} />
                  </FormControl>
                </CSSTransition>
              )}
            </TransitionGroup>
          </div>
        </CSSTransition>
      </div>
      <CSSTransition
        classNames="hg-submit"
        in={hasSelectedGuest && showDiet}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 1000, exit: 500 }}
      >
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <StyledButton disabled={loadingRSVP} className={buttonClassname} onClick={handleSubmit}>
            {rsvpSuccess ? 'SUCCESS!' : loadingRSVP ? <span>SUBMITTING<CircularProgress size={16} className={classes.buttonProgress} /></span> : 'SUBMIT'}
          </StyledButton>
        </div>
      </CSSTransition>
      <CSSTransition
        classNames="hg-submit"
        in={rsvpSuccess}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 1000, exit: 500 }}
      >
        <div style={{ display: 'flex', marginTop: '12px', flexDirection: 'row-reverse' }}>
          <BigText>
            MORE EVENT INFO BELOW
          </BigText>
        </div>
      </CSSTransition>
    </StyledForm>
  )
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Form
