import PropTypes from "prop-types"
import styled from "@emotion/styled"
import React from "react"

const Bus = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="73.37px" height="20.296px" viewBox="0 0 73.37 20.296" enable-background="new 0 0 73.37 20.296" xmlSpace="preserve">
<g>
	<g>
		<g>
			<path fill="#725B48" d="M62.008,16.941c0,1.853,1.501,3.354,3.355,3.354c1.853,0,3.355-1.502,3.355-3.354
				c0-1.854-1.502-3.355-3.355-3.355C63.51,13.586,62.008,15.087,62.008,16.941z M63.905,16.941c0-0.807,0.653-1.459,1.459-1.459
				c0.805,0,1.458,0.652,1.458,1.459c0,0.805-0.653,1.458-1.458,1.458C64.558,18.399,63.905,17.746,63.905,16.941z"/>
		</g>
		<g>
			<path fill="#725B48" d="M42.311,16.941c0,1.853,1.501,3.354,3.355,3.354c1.854,0,3.355-1.502,3.355-3.354
				c0-1.854-1.502-3.355-3.355-3.355C43.812,13.586,42.311,15.087,42.311,16.941z M44.207,16.941c0-0.807,0.653-1.459,1.459-1.459
				s1.458,0.652,1.458,1.459c0,0.805-0.653,1.458-1.458,1.458S44.207,17.746,44.207,16.941z"/>
		</g>
		<g>
			<path fill="#725B48" d="M37.258,2.004v12.932c0,1.104,0.902,2.006,2.005,2.006h2.446c0-2.183,1.775-3.957,3.957-3.957
				c2.181,0,3.957,1.775,3.957,3.957h11.784c0-2.183,1.775-3.957,3.957-3.957s3.957,1.775,3.957,3.957h2.044
				c1.103,0,2.005-0.903,2.005-2.006v-4.61c0-1.103-0.18-2.89-0.401-3.97l-1.203-4.392C71.545,0.884,70.462,0,69.36,0H39.263
				C38.16,0,37.258,0.901,37.258,2.004z M65.038,8.459V1.201h3.971c0.827,0,1.627,0.665,1.778,1.479l1.078,4.301
				c0.127,0.688-0.25,1.269-0.87,1.432l-0.918-2.175c-0.129-0.306-0.482-0.449-0.788-0.32s-0.449,0.482-0.32,0.788l0.741,1.754
				H65.038L65.038,8.459z M56.116,1.201h7.719v7.258h-7.719V1.201z M47.195,1.201h7.718v7.258h-7.718V1.201z M38.487,2.705
				c0-0.828,0.677-1.504,1.503-1.504h6.001v7.258h-6.001c-0.827,0-1.503-0.677-1.503-1.504V2.705z"/>
		</g>
	</g>
</g>
<g>
	<g>
		<line fill="none" stroke="#725B48" stroke-width="2" stroke-miterlimit="10" x1="37.258" y1="13.586" x2="36.258" y2="13.586"/>
		
			<line fill="none" stroke="#725B48" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="2.074,2.074" x1="34.184" y1="13.586" x2="2.037" y2="13.586"/>
		<line fill="none" stroke="#725B48" stroke-width="2" stroke-miterlimit="10" x1="1" y1="13.586" x2="0" y2="13.586"/>
	</g>
</g>
</svg>


const SmallText = styled.p`
  font-weight: 300;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 2px;
  line-height: 20px;
  padding: 16px 0 8px 0;
  margin: 0;
  text-align: left;

  @media only screen and (max-width: 1024px) {
    padding: 8px 16px 8px 6px;
    letter-spacing: 2px;
  }
`

const Header = styled.h2`
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 4px;
  color: #725b48;
  padding: 0;
  margin: 0;
  // line-height: 25px;
  align-self: end;
  text-align: left;
`

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: auto 80px;
  margin: 0 0 24px 0;
`

const Bot = styled.div`
  margin: 48px 0;
`

const Transportation = () => (
  <div id="hg-transportation">
    <Bot >
    <HeaderGrid>
      <Header>TRANSPORTATION</Header>
      <Bus />
    </HeaderGrid>
    <SmallText>As parking is extremely limited at Seacliff House, we've arranged a bus to pick you up and drop you home! The route will service Gerroa, Gerringong and Kiama areas.</SmallText>
    <SmallText>Please provide us your accommodation details as early as possible, and we'll create a personalised bus timetable based on where you're staying.</SmallText>
    <SmallText>Check back here for the timetable approximately 2 weeks prior to the event!</SmallText>
    <SmallText>We’d hate for you to miss the bus!</SmallText>
    </Bot>
  </div>
)

Transportation.propTypes = {
  siteTitle: PropTypes.string,
}

Transportation.defaultProps = {
  siteTitle: ``,
}

export default Transportation
