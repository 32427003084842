/* globals window document */
import React, { useState, useEffect } from "react"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import 'intersection-observer'
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import { useInView } from 'react-intersection-observer'

import Layout from "../components/layout"

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const Loader = styled.div`
  pointer-events: none;
  z-index: 42069;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: ${({ loaded }) => loaded ? 'default' : 'wait' };
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ loaded }) => loaded ? 'transparent' : 'white' };
  > * {
    transition: opacity 0.5s linear;
    opacity: ${({ loaded }) => loaded ? '0' : '1'};
  }
`

const IndexPage = () => {
  const [portraitLoaded, setPortraitLoaded] = useState(false)
  const [writingLoaded, setWritingLoaded] = useState(false)
  const loaded = portraitLoaded && writingLoaded

  useEffect(() => {
    const timer = setTimeout(() => {
      setPortraitLoaded(true)
      setWritingLoaded(true)
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#9F836B" />
        <link rel="icon" href="https://abaio.s3-ap-southeast-2.amazonaws.com/heart.png" type="image/gif" sizes="16x16"/> 
        <title>Happily Ever Hogan</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Loader loaded={loaded}>
        {/* ENABLE THIS FOR HEART LOADING SPINNER */}
        <div class="loadingio-spinner-heart-gpprw9x2vi">
          <div class="ldio-x8gpio651fb">
            <div><div></div></div>
          </div>
        </div>
      </Loader>
    <div id="bg">
      <img src="https://abaio.s3-ap-southeast-2.amazonaws.com/AA_Portrait.png" alt="" onLoad={() => setPortraitLoaded(true)} />
    </div>
    <ThemeProvider theme={theme}>
      <Layout 
        // IORef={ref}
        setWritingLoaded={setWritingLoaded}
        setPortraitLoaded={setPortraitLoaded}
      >
      </Layout>
    </ThemeProvider>
    </>
)
  }

export default IndexPage
