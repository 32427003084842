import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

const House = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="28.492px" height="27.596px" viewBox="0 0 28.492 27.596" enable-background="new 0 0 28.492 27.596" xmlSpace="preserve">
<path fill-rule="evenodd" clip-rule="evenodd" fill="#735C49" d="M4.591,13.251l9.356-8.995c0.168-0.162,0.433-0.16,0.599,0.001l0,0
	l0.002,0.003l9.258,8.993l0.003,0.003l0.302,0.271c0.096,0.084,0.144,0.202,0.144,0.32h0.002v13.318
	c0,0.238-0.193,0.432-0.433,0.432h-6.629v-8.098c0-0.238-0.193-0.432-0.432-0.432h-5.034c-0.238,0-0.432,0.193-0.432,0.432v8.098
	h-6.63c-0.238,0-0.431-0.193-0.431-0.432v-13.39c0-0.133,0.061-0.253,0.155-0.332L4.591,13.251z"/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#735C49" d="M14.246,2.051L1.457,14.197c-0.344,0.327-0.891,0.314-1.219-0.031
	c-0.328-0.345-0.314-0.892,0.03-1.219l13.385-12.71c0.333-0.316,0.855-0.316,1.188,0l13.384,12.71
	c0.346,0.327,0.358,0.874,0.031,1.219c-0.328,0.345-0.875,0.358-1.221,0.031L14.246,2.051z"/>
<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#735C49" points="6.588,8.37 4.892,10.8 3.371,10.8 3.371,3.328 
	7.659,3.328 7.659,8.212 "/>
</svg>

const Header = styled.h2`
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 4px;
  color: #725b48;
  padding: 0;
  margin: 0;
  // line-height: 25px;
  align-self: end;
  text-align: left;
`

const SmallText = styled.p`
  font-weight: 300;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 2px;
  line-height: 20px;
  padding: 16px 0 8px 0;
  margin: 0;
  text-align: left;

  @media only screen and (max-width: 1024px) {
    padding: 8px 16px 8px 6px;
    letter-spacing: 2px;
  }
`

const UlyBoi = styled.ul`
  margin: 32px 8px 16px 0;
`

const ListyBoi = styled.li`
  font-weight: 300;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 2px;
  line-height: 20px;
  text-align: justify;
  // padding: 8px 0;
  margin: 0;
  list-style-type: "\-";
  list-style-position: inside;

  span {
    padding-left: 16px;
  }

  @media only screen and (max-width: 1024px) {
    letter-spacing: 0px;
    padding: 4px 16px 4px 0;
  }
`

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: auto 30px;
  margin: 0 0 24px 0;
`

const ABoy = styled.a`
  color: #725b48;
  text-decoration: none;
  font-weight: 400;
`

const Accommodation = () => (
  <div id='hg-accommodation'>
    <HeaderGrid>
      <Header>ACCOMMODATION</Header>
      <House />
    </HeaderGrid>
    <SmallText>
    We are very excited to celebrate with you and appreciate the distance many of you will travel to share our wedding day. We’re hoping you’ll be able to find somewhere to stay over the weekend to create magical memories with your loved ones.

    </SmallText>
    <SmallText>
    There are plenty of options for all budgets to be found using Airbnb and Stayz search engines, but here is a list of local favourites to get you started.
    </SmallText>

    <UlyBoi>
      <ListyBoi><span><ABoy href="https://www.accorhotels.com/gb/hotel-8610-mercure-gerringong-resort/index.shtml%20/t%20_blank" target="_blank">The Mercure Gerringong Resort</ABoy></span></ListyBoi>
      <ListyBoi><span><ABoy href="https://saltashsouthcoast.com.au/" target="_blank">Saltash South Coast</ABoy></span></ListyBoi>
      <ListyBoi><span><ABoy href="http://dunoonfarmhouse.com.au/" target="_blank">Dunoon Farmhouse</ABoy></span></ListyBoi>
      <ListyBoi><span><ABoy href="https://soulofgerringong.com.au/" target="_blank">Soul of Gerringong</ABoy></span></ListyBoi>
      <ListyBoi><span><ABoy href="https://www.oceanfarm.com.au/" target="_blank">Ocean Farm</ABoy></span></ListyBoi>
      <ListyBoi><span><ABoy href="http://www.southcoastholidays.com.au/accommodation/THE-SHED-Gerroa-4pm-checkout-on-Sundays-Public-Holidays/184-THE-SHED-Gerroa-4pm-checkout-on-Sundays-Public-Holidays/%20/t%20_blank" target="_blank">The Shed in Gerroa</ABoy></span></ListyBoi>
      <ListyBoi><span><ABoy href="https://www.sundarabeachhouse.com/" target="_blank">Sundara Beach House</ABoy></span></ListyBoi>
      <ListyBoi><span><ABoy href="https://dovecote.com.au/" target="_blank">Dovecote</ABoy></span></ListyBoi>
    </UlyBoi>
  </div>
)

Accommodation.propTypes = {
  siteTitle: PropTypes.string,
}

Accommodation.defaultProps = {
  siteTitle: ``,
}

export default Accommodation
